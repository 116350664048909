html, body, #root, .SignIn {
    height: 100%;
}

.SignIn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
}

.SignIn .sign-in {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}

.SignIn .sign-in .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}

.SignIn .sign-in .form-control:focus {
    z-index: 2;
}
.SignIn .sign-in input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.SignIn .sign-in input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.SignIn .btn-sign-in span[role=status] {
    margin-right: 10px;
}