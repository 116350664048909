.App {
  padding-left: 0;
  padding-right: 0;
}
.scrollable-container {
  width: 100%;
  /*height: 75vh;*/
  /*overflow: auto;*/
}

.properties-toolbar {
  padding: 5px;
}